<template>
  <div class="wrap">
    <div class="banner">
      <img v-if="clientWidth > 750" :src="bannerImg" alt="">
      <img v-else :src="bannerImg_m" alt="">
    </div>
    <div class="module" v-if="true">
      <div class="module-intro">
        <p>“融汇数字创新  释放工业动能”2023 剑维软件行业研讨会，即将在宁波、西安和沈阳精彩启幕。与您探讨如何创新数字技术，并与应用场景深入融合，从而激发工业创造力，释放工业动能。</p> 
        <p>50 多年的行业深耕，AVEVA 剑维软件致力于与生态圈合作伙伴协同创新，让绿色数字技术切实扎根于工业场景。此次研讨会，将荟聚水务、电子半导体、基础设施、钢铁、冶金等行业的智库学者、商界领袖、技术行家，探讨未来发展趋势，分享产品功能新亮点、行业应用特性及成功案例实践，在 demo 展示区沉浸式互动交流，推动业务创新增长。</p>
        <p>驭剑同行，行业共创。未来之路，让我们一起携手并进，在数字化和低碳化双转型中破局开拓，融汇数字创新力，实现工业行业高质量发展。期待您准时参会！</p>
      </div>
      <div class="module-title">点击下方按钮了解不同城市日程安排</div>
      <div class="module-box flex7">
        <div class="module-box-content flex0" :class="clientWidth > 750 ? (currentIndex == index ? 'select-active module-box-address' : 'module-box-address') : currentIndex == index ? 'select-active' : ''" :style="clientWidth > 750 ? (currentIndex == index ? item.image_address_active : item.image_address) : (currentIndex == index ? item.image_m_active : item.image_m)" v-for="(item, index) in roles" :key="item.value" @click="clickRadio(item, index)">
          <div class="module-content-nav">
            <div class="nav-time">
              <img :src="currentIndex == index ? timeImg_active : timeImg" alt="">
              <span>{{item.name}}</span>
            </div>
            <div class="nav-address">
              <img :src="currentIndex == index ? addressImg_active : addressImg" alt="">
              <span>{{item.address}}</span>
            </div>
          </div>
          <div class="module-content-address" v-if="clientWidth > 750">
            <p>{{item.addressDetail1}}</p>
            <p>{{item.addressDetail2}}</p>
          </div>
        </div>
      </div>

      <div class="module-date-box">
        <div class="address-mobile" v-if="clientWidth <= 750 && addressDetail1">
          <p>{{addressDetail1}}</p>
          <p>{{addressDetail2}}</p>
        </div>
        
        <div class="date-title" v-if="dateData.length > 0">日程安排</div>
        <div class="data-more" v-if="dateData.length == 0">日程安排敬请期待...</div>
        <div class="item" v-if="dateData.length > 0">
          <table>
            <tbody>
              <tr>
                <td class="item-header-left">时间</td>
                <td class="item-header-center">主题</td>
                <td class="item-header-right">嘉宾</td>
              </tr>
              <tr v-for="(item, index) in dateData" :key="index">
                <td>{{item.time}}</td>
                <td>{{item.content}}</td>
                <td>{{item.people}}</td>
              </tr>
            </tbody>
          </table>
          <div class="item-more">* 会议内容将不断更新完善。</div>
        </div>
        <div class="date-title" v-if="peopleData.length > 0">演讲嘉宾</div>
        <div class="people-box" v-if="peopleData.length > 0">
          <div class="people" v-for="(item, index) in peopleData.filter(item => item.img)" :key="index">
            <div class="img_box" v-if="!item.img.length"></div>
            <img :src="item.img" v-else alt="">
            <div class="people-content">
              <p class="people-name">{{item.name}}</p>
              <p class="people-address" v-if="clientWidth > 750 && item.address" v-html="item.address"></p>
              <p class="people-address" v-if="clientWidth <= 750 && item.address_m" v-html="item.address_m"></p>
              <p class="people-position" v-if="item.position" v-html="item.position"></p>
            </div>
            <p class="people-bottom"></p>
          </div>
        </div>
        <!-- v-if="!isNoAddressNav" -->
        <div class="ercode-box" v-if="isNoAddressNav">
          <div class="ercode-img">
            <img :src="ercode_haveaddress" alt="">
          </div>
          <div class="ercode-text">
            <span v-if="clientWidth > 750">本次研讨会席位有限，现在就注册参会吧! <br> AVEVA 剑维软件期待您的莅临!
            </span>
            <span v-else>本次研讨会席位有限，现在就注册参会吧!<br> AVEVA 剑维软件期待您的莅临!
            </span>
            <div class="ercode-icon">
              <img :src="phoneImg" alt="">
              <span>会务联系人：邢强18519000588</span>
            </div>
            <!-- <span>尽享巡展精彩</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { GetWxShares } from "@/utils/common";
import { GetTreeSelect } from "@/api/Home.js"
import { ApiToken } from '@/utils/ApiToken'

export default {
  data(){
    return{
      currentIndex: 0,
      isNoAddressNav: true,
      bannerImg: require('@/assets/PC/activity/banner1.jpg'),
      bannerImg_m: require('@/assets/PC/activity/banner1_m.png'),
      timeImg: require('@/assets/PC/newpage/time.png'),
      timeImg_active: require('@/assets/PC/newpage/time_active.png'),
      addressImg: require('@/assets/PC/newpage/address.png'),
      addressImg_active: require('@/assets/PC/newpage/address_active.png'),
      ercode_noaddress: require('@/assets/PC/newpage/ercode_noaddress.png'),
      ercode_haveaddress: require('@/assets/PC/activity/register_ercode.png'),
      iconErcodeImg: require('@/assets/PC/newpage/icon_ercode.png'),
      peopleMoreImg: require('@/assets/PC/newpage/people_more.png'),
      phoneImg: require('@/assets/PC/newpage/icon_phone.png'),

      pauseIcon: require('@/assets/PC/newpage/icon_pause.png'),
      timeIcon: require('@/assets/PC/newpage/icon_time.png'),
      textIcon: require('@/assets/PC/newpage/icon_text.png'),
      roles: [{
        name: '9月12日',
        address: '宁波',
        image: { backgroundImage: "url(" + require("@/assets/PC/newpage/back1.png") + ")" },
        image_active: { backgroundImage: "url(" + require("@/assets/PC/activity/back_active1.png") + ")" },
        image_address: { backgroundImage: "url(" + require("@/assets/PC/activity/back1.png") + ")" },
        image_address_active: { backgroundImage: "url(" + require("@/assets/PC/activity/back_active1.png") + ")" },
        image_m: { backgroundImage: "url(" + require("@/assets/PC/activity/back_m1.png") + ")" },
        image_m_active: { backgroundImage: "url(" + require("@/assets/PC/activity/back_m_active1.png") + ")" },
        addressDetail1: '地点：宁波洲际酒店',
        addressDetail2: '地址：浙江，宁波，鄞州区，新晖路777号',
        dateData: [
          {time: '08:30-09:00', content: '签到', people: ''},
          {time: '09:00-09:10', content: '开场致辞', people: '雍丽'},
          {time: '09:10-09:25', content: 'AVEVA 工业数字化转型方案', people: '曹科'},
          {time: '09:25-10:10', content: 'AVEVA 面向未来的运营控制解决方案', people: '毕鹏辉'},
          {time: '10:10-10:25', content: '茶歇', people: ''},
          {time: '10:25-10:55', content: '城镇水务工控系统的安全运营', people: '卢汉清'},
          {time: '10:55-11:25', content: '排水公司系统平台案例分享', people: '单铃琳'},
          {time: '11:25-11:55', content: '同“芯”协力  携“数”而行 - 洁净工厂 FMCS 系统集成应用分享', people: '张大博'},
          {time: '11:55-13:30', content: '午餐', people: ''},
          {time: '13:30-14:00', content: 'AVEVA 工业软件平台赋能设备数字化运维', people: '汪昆'},
          {time: '14:00-14:30', content: 'AVEVA Monitor & Control 结合行业应用的产品特性分享', people: '胡绍宝'},
          {time: '14:30-15:00', content: '基于 AVEVA 系统平台的二次供水集控系统', people: '陈良良'},
          {time: '15:00-15:15', content: '茶歇', people: ''},
          {time: '15:15-15:45', content: 'PI System 解决方案及水行业案例分享', people: '尚真'},
          {time: '15:45-16:15', content: 'AVEVA 工业软件平台在电子半导体行业的应用实践', people: '包震'},
          {time: '16:15-17:00', content: 'AVEVA 系统平台的最佳实践', people: '林碧华'},
          {time: '17:00-17:05', content: '闭幕致辞', people: '吴美玲'}
        ],
        peopleData: [
          {
            img: require('@/assets/PC/activity/people_yl.png'),
            name: '雍丽',
            address: '剑维软件中国区',
            address_m: '剑维软件中国区',
            position: '渠道销售总监'
          },{
            img: require('@/assets/PC/activity/people_ck.png'),
            name: '曹科',
            address: '剑维软件中国区',
            address_m: '剑维软件中国区',
            position: '售前技术总监'
          },{
            img: require('@/assets/PC/activity/people_bph.png'),
            name: '毕鹏辉',
            address: '剑维软件中国区',
            address_m: '剑维软件中国区',
            position: '首席售前技术专家'
          },{
            img: require('@/assets/PC/activity/people_lhj.png'),
            name: '卢汉清',
            address: '宁波市水务环境集团有限公司',
            address_m: '宁波市水务环境集团有限公司',
            position: '水务科技中心主任，给排水教授级<br>高工、电气自动化教授级高工'
          },{
            img: require('@/assets/PC/activity/people_sll.png'),
            name: '单铃琳',
            address: '江苏四联水务科技有限公司',
            address_m: '江苏四联水务科技有限公司',
            position: '市场部经理'
          },{
            img: require('@/assets/PC/activity/people_zdb.png'),
            name: '张大博',
            address: '上海集灵信息技术有限公司',
            address_m: '上海集灵信息技术有限公司',
            position: '副总经理'
          },{
            img:  require('@/assets/PC/activity/people_wk.png'),
            name: '汪昆',
            address: '苏州数字星空自动化有限公司',
            address_m: '苏州数字星空自动化有限公司',
            position: '总经理'
          },{
            img: require('@/assets/PC/activity/people_hsb.jpg'),
            name: '胡绍宝',
            address: '剑维软件中国区',
            address_m: '剑维软件中国区',
            position: '资深售前技术专家'
          },{
            img: require('@/assets/PC/activity/people_cll.png'),
            name: '陈良良',
            address: '嘉兴澳赛科技有限公司',
            address_m: '嘉兴澳赛科技有限公司',
            position: '技术总监'
          },{
            img: require('@/assets/PC/activity/people_sz.png'),
            name: '尚真',
            address: '剑维软件中国区',
            address_m: '剑维软件中国区',
            position: '资深售前技术专家'
          },{
            img: require('@/assets/PC/activity/people_bz.png'),
            name: '包震',
            address: '上海蓝鸟机电有限公司',
            address_m: '上海蓝鸟机电有限公司',
            position: '技术总监'
          },{
            img: '',
            name: '林碧华',
            address: '剑维软件中国区',
            address_m: '剑维软件中国区',
            position: '高级技术支持工程师'
          },{
            img: '',
            name: '吴美玲',
            address: '剑维软件中国区',
            address_m: '剑维软件中国区',
            position: '渠道销售经理'
          }
        ]
      },{
        name: '9月14日',
        address: '西安',
        image: { backgroundImage: "url(" + require("@/assets/PC/newpage/back2.png") + ")" },
        image_active: { backgroundImage: "url(" + require("@/assets/PC/newpage/back_active2.png") + ")" },
        image_address: { backgroundImage: "url(" + require("@/assets/PC/activity/back2.png") + ")" },
        image_address_active: { backgroundImage: "url(" + require("@/assets/PC/activity/back_active2.png") + ")" },
        image_m: { backgroundImage: "url(" + require("@/assets/PC/activity/back_m2.png") + ")" },
        image_m_active: { backgroundImage: "url(" + require("@/assets/PC/activity/back_m_active2.png") + ")" },
        addressDetail1: '地点：西安高新区喜来登酒店',
        addressDetail2: '地址：陕西，西安，雁塔区，高新区天谷七路99号',
        dateData: [
          {time: '08:30-09:00', content: '签到', people: ''},
          {time: '09:00-09:10', content: '开场致辞', people: '雍丽'},
          {time: '09:10-09:25', content: 'AVEVA 工业数字化转型方案', people: '曹科'},
          {time: '09:25-10:10', content: 'AVEVA 面向未来的运营控制解决方案', people: '毕鹏辉'},
          {time: '10:10-10:25', content: '茶歇', people: ''},
          {time: '10:25-10:55', content: 'AVEVA 系统平台在长距离智慧供水项目的应用', people: '谭伟杰'},
          {time: '10:55-11:25', content: '超网实业助力企业数智化建设', people: '张维'},
          {time: '11:25-11:55', content: '煤矿数字化运营实践之路', people: '刘硕'},
          {time: '11:55-13:30', content: '午餐', people: ''},
          {time: '13:30-14:00', content: '基于 AVEVA 系统平台数据驱动的智能运维', people: '唐明'},
          {time: '14:00-14:30', content: 'AVEVA Monitor & Control 结合行业应用的产品特性分享', people: '胡绍宝'},
          {time: '14:30-15:15', content: 'AVEVA 系统平台的最佳实践', people: '郑淮文'},
          {time: '15:15-15:30', content: '茶歇', people: ''},
          {time: '15:30-16:00', content: 'PI System 解决方案及水行业案例分享', people: '尚真'},
          {time: '16:00-16:30', content: '基于流程化工数字化的端到端全生命周期解决方案', people: '陶凯'},
          {time: '16:30-17:00', content: 'AVEVA 智慧城市 - 防洪排涝数字化应用', people: '祝攀成'},
          {time: '17:00-17:05', content: '闭幕致辞', people: '王辉'}
        ],
        peopleData: [
          {
            img: require('@/assets/PC/activity/people_yl.png'),
            name: '雍丽',
            address: '剑维软件中国区',
            address_m: '剑维软件中国区',
            position: '渠道销售总监'
          },{
            img:  require('@/assets/PC/activity/people_ck.png'),
            name: '曹科',
            address: '剑维软件中国区',
            address_m: '剑维软件中国区',
            position: '售前技术总监'
          },{
            img: require('@/assets/PC/activity/people_bph.png'),
            name: '毕鹏辉',
            address: '剑维软件中国区',
            address_m: '剑维软件中国区',
            position: '首席售前技术专家'
          },{
            img: require('@/assets/PC/activity/people_twj.png'),
            name: '谭伟杰',
            address: '西安航天自动化股份有限公司',
            address_m: '西安航天自动化股份有限公司',
            position: '项目经理'
          },{
            img: require('@/assets/PC/activity/people_zw.png'),
            name: '张维',
            address: '超网实业(成都)股份有限公司',
            address_m: '超网实业(成都)股份有限公司',
            position: '智能制造部西区负责人'
          },{
            img: '',
            name: '刘硕',
            address: '北斗天地股份有限公司',
            address_m: '北斗天地股份有限公司',
            position: '自动化部部长'
          },{
            img: require('@/assets/PC/activity/people_tm.png'),
            name: '唐明',
            address: '四川星环纪元科技发展有限公司',
            address_m: '四川星环纪元科技发展有限公司',
            position: '总经理'
          },{
            img: require('@/assets/PC/activity/people_hsb.jpg'),
            name: '胡绍宝',
            address: '剑维软件中国区',
            address_m: '剑维软件中国区',
            position: '资深售前技术专家'
          },{
            img: '',
            name: '郑淮文',
            address: '剑维软件中国区',
            address_m: '剑维软件中国区',
            position: '资深售后技术专家'
          },{
            img: require('@/assets/PC/activity/people_sz.png'),
            name: '尚真',
            address: '剑维软件中国区',
            address_m: '剑维软件中国区',
            position: '资深售前技术专家'
          },{
            img: require('@/assets/PC/activity/people_tk.png'),
            name: '陶凯',
            address: '南京强思数字科技有限公司',
            address_m: '南京强思数字科技有限公司',
            position: '化工事业部总经理'
          },{
            img: require('@/assets/PC/activity/people_zpc.png'),
            name: '祝攀成',
            address: '四川高威新潮电气技术有限公司',
            address_m: '四川高威新潮电气技术有限公司',
            position: '技术经理'
          },{
            img: require('@/assets/PC/activity/people_wh.png'),
            name: '王辉',
            address: '剑维软件中国区',
            address_m: '剑维软件中国区',
            position: '渠道销售经理'
          }
        ]
      },{
        name: '10月18日',
        address: '沈阳',
        image: { backgroundImage: "url(" + require("@/assets/PC/newpage/back3.png") + ")" },
        image_active: { backgroundImage: "url(" + require("@/assets/PC/newpage/back_active3.png") + ")" },
        image_address: { backgroundImage: "url(" + require("@/assets/PC/activity/back3.png") + ")" },
        image_address_active: { backgroundImage: "url(" + require("@/assets/PC/activity/back_active3.png") + ")" },
        image_m: { backgroundImage: "url(" + require("@/assets/PC/activity/back_m3.png") + ")" },
        image_m_active: { backgroundImage: "url(" + require("@/assets/PC/activity/back_m_active3.png") + ")" },
        addressDetail1: '地点：沈阳威斯汀酒店',
        addressDetail2: '地址：辽宁，沈阳，和平区，青年大街386号',
        dateData:  [
          {time: '08:30-09:00', content: '签到', people: ''},
          {time: '09:00-09:10', content: '开场致辞', people: '倪焕哲'},
          {time: '09:10-09:35', content: 'AVEVA 工业数字化转型方案', people: '郭睿'},
          {time: '09:35-10:15', content: 'AVEVA 面向未来的运营控制解决方案', people: '毕鹏辉'},
          {time: '10:15-10:30', content: '茶歇', people: ''},
          {time: '10:30-11:00', content: '基于 AVEVA System Platform 的水电厂自动化监控系统介绍', people: '黄琢'},
          {time: '11:00-11:30', content: '新松智慧城轨解决方案', people: '徐建民'},
          {time: '11:30-12:00', content: '数往知来 智领前行', people: '王承龙'},
          {time: '12:00-13:30', content: '午餐', people: ''},
          {time: '13:30-14:00', content: '电子半导体厂务 GMS / GDS 特气监控及侦测系统的应用', people: '陈文钦'},
          {time: '14:00-14:30', content: '高质量数字化交付推进数字化工厂应用', people: '焦大伟'},
          {time: '14:30-15:00', content: 'AVEVA Monitor & Control 结合行业应用的产品特性分享', people: '胡绍宝'},
          {time: '15:00-15:15', content: '茶歇', people: ''},
          {time: '15:15-15:45', content: '工业自动化石油和天然气领域集中监视和数据采集', people: '董君'},
          {time: '15:45-16:30', content: 'AVEVA 系统平台的最佳实践', people: '叶峰'},
          {time: '16:30-17:00', content: 'AVEVA 软件在行业中的应用实践', people: '莫春平'},
          {time: '17:00-17:05', content: '闭幕致辞', people: '倪焕哲'}
        ],
        peopleData: [{
          img: '',
          name: '倪焕哲',
          address: '剑维软件中国区',
          address_m: '剑维软件中国区',
          position: '渠道销售经理'
        },{
          img: '',
          name: '郭睿',
          address: '剑维软件中国区',
          address_m: '剑维软件中国区',
          position: '渠道销售经理'
        },{
          img: require('@/assets/PC/activity/people_bph.png'),
          name: '毕鹏辉',
          address: '剑维软件中国区',
          address_m: '剑维软件中国区',
          position: '首席售前技术专家'
        },{
          img: '',
          name: '黄琢',
          address: '国网辽宁省电力有限公司电力科学研究院',
          address_m: '国网辽宁省电力有限公司电力科学研究院',
          position: '技术经理'
        },{
          img: '',
          name: '徐建民',
          address: '沈阳新松机器人自动化股份有限公司',
          address_m: '沈阳新松机器人自动化股份有限公司',
          position: '轨道交通事业部总工程师'
        },{
          img: '',
          name: '王承龙',
          address: '大连共兴达信息技术有限公司',
          address_m: '大连共兴达信息技术有限公司',
          position: '市场部总监'
        },{
          img: '',
          name: '陈文钦',
          address: '江苏虎虎机电科技有限公司',
          address_m: '江苏虎虎机电科技有限公司',
          position: '技术总监'
        },{
          img: '',
          name: '焦大伟',
          address: '中石油吉林化工工程有限公司',
          address_m: '中石油吉林化工工程有限公司',
          position: '信息数智化部主任'
        },{
          img: require('@/assets/PC/activity/people_hsb.jpg'),
          name: '胡绍宝',
          address: '剑维软件中国区',
          address_m: '剑维软件中国区',
          position: '资深售前技术专家'
        },{
          img: '',
          name: '董君',
          address: '沈阳兰星自动化工程有限公司',
          address_m: '沈阳兰星自动化工程有限公司',
          position: '系统工程师'
        },{
          img: '',
          name: '叶峰',
          address: '剑维软件中国区',
          address_m: '剑维软件中国区',
          position: '资深售后技术专家'
        },{
          img: '',
          name: '莫春平',
          address: '北京汉锦电子自动化系统有限公司',
          address_m: '北京汉锦电子自动化系统有限公司',
          position: '技术顾问'
        },{
          img: '',
          name: '倪焕哲',
          address: '剑维软件中国区',
          address_m: '剑维软件中国区',
          position: '渠道销售经理'
        }]
      }
    ],
      peopleData: [],
      activityData: [
        {
          img: require('@/assets/PC/newpage/activity1.png'),
        },{
          img: require('@/assets/PC/newpage/activity2.png'),
        },{
          img: require('@/assets/PC/newpage/activity3.png'),
        },{
          img: require('@/assets/PC/newpage/activity4.png'),
        },{
          img: require('@/assets/PC/newpage/activity5.png'),
        }
      ],
      dateData: [],
      addressDetail1: '',
      addressDetail2: '',

      activityList: []
    }
  },
  mounted() {
    if(this.$route.query.address == 'site'){
      this.isNoAddressNav = false
    }else{
      this.isNoAddressNav = true
    }
    if(this.$route.query.index){
      let index = this.$route.query.index ? this.$route.query.index : 0
      this.currentIndex = index
      this.dateData = this.roles[index].dateData
      this.addressDetail1 = this.roles[index].addressDetail1
      this.addressDetail2 = this.roles[index].addressDetail2
      this.peopleData = this.roles[index].peopleData
    }else{
      this.dateData = this.roles[0].dateData
      this.addressDetail1 = this.roles[0].addressDetail1
      this.addressDetail2 = this.roles[0].addressDetail2
      this.peopleData = this.roles[0].peopleData
    }
    let data = {
      pid: ApiToken.roadShowId,
      id: ''
    }
    this.GetTreeSelect(data)
    
    let obj = {
      title: 'AVEVA 剑盟社区',
      // desc: '剑指无界，共盟新程'
    }
    GetWxShares(obj)
  },
  methods: {
    GetTreeSelect(data){
      GetTreeSelect(data).then(res =>{
        if(res.status == 1){
          this.activityList = res.data
        }else{
          this.$toast(res.message);
        }
        
      })
    },
    clickRadio (item, index){
      if(this.currentIndex != index){
        this.currentIndex = index
        this.dateData = item.dateData
        this.addressDetail1 = item.addressDetail1
        this.addressDetail2 = item.addressDetail2
        this.peopleData = item.peopleData
      }
    },
    goNext(item) {
      window.open(item.extend4)
    }
  },
  computed: {
    ...mapState(['clientWidth'])
  },
};
</script>
<style lang="scss" scoped>
$mobil_base: 750;
@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}
.wrap{
  width: 100%;
  overflow: hidden;
  font-size: 30px;
}
.banner{
  width: 100%;
  height: auto;
  display: block;
  img{
    width: 100%;
    height: auto;
    display: block;
  }
}
.module{
  width: 1200px;
  margin: 0 auto 70px;
}
.module-intro{font-size: 16px; line-height: 32px;padding: 81px 0 37px; 
  p{font-size: 16px; text-indent: 32px; margin-bottom: 20px;}
}
.module-title{width: 100%;font-size: 20px; color: #434343; font-weight: bold; margin: 0 0 20px;}
.module-box{
  .module-box-content{
    width: 300px;
    height: 97px;
    background-size: 300px 97px;
    font-size: 20px;
    border-radius: 5px;
    color: #000;
    cursor: pointer;
    justify-content: flex-start;
    padding-left: 30px;
    .module-content-nav{
      width: 100%;
      display: flex;
      .nav-time, .nav-address{
        display: flex;
        align-items: center;
        img{
          margin-right: 7px;
        }
        span{
          font-size: 16px;
          margin-top: -3px;
        }
      }
      .nav-time{
        img{
          width: 18px;
          height: 18px;
        }
      }
      .nav-address{
        margin-left: 20px;
        img{
          width: 16px;
          height: 20px;
        }
      }
    }
    
  }
  .module-box-address{
    width: 310px;
    height: 147px;
    background-size: 310px 147px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 15px;
    .module-content-nav{
      margin-top: 10px;
    }
    .module-content-address{
      margin-top: 32px;
      p{
        font-size: 13px;
        line-height: 22px;
        height: 22px;
      }
    }
  }
  .select-active{
    position: relative; 
    font-size: 24px; 
    color: #fff; 
    cursor: default;}
  .select-active::before{
    content: '';
    width: 0;
    height: 0;
    border-right: 8px solid transparent;
    border-top:12px solid transparent;
    border-bottom: 12px solid #FFFFFF;
    border-left:8px solid transparent;
    position: absolute;
    bottom: -41px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 12;
  }
  .select-active::after{
    width: 0px;
    height: 0px;
    border-right: 9px solid transparent;
    border-top:12px solid transparent;
    border-bottom:13px solid #6b18a8;
    border-left:9px solid transparent;
    display: block;
    content:'';
    position: absolute;
    bottom: -41px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }
  .module-box-content:nth-of-type(4n) { margin-right: 0 }
}
.module-date-box{
  margin-top: 40px;
  width: 100%;
  border: 1px solid #6B18A8;
  border-radius: 16px;
  position: relative;
  z-index: 9;
  padding: 0 35px 67px;
  .date-title{
    font-size: 28px;
    font-weight: bold;
    margin-top: 45px;
  }
  .data-more{
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    color: #000;
    margin: 40px 0;
  }
}

.item{
  margin-top: 19px;
  .item-more{
    margin-top: 10px;
    font-size: 14px;
    color: #5f6368;
  }
  .item-header-left{
    width: 20%;
  }
  .item-header-center{
    width: 60%;
  }
  .item-header-right{
    width: 20%;
  }
  .item-header-center, .item-header-right{
    padding: 0 !important;
    text-align: center !important;
  }
  table{
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #aaa;
    tr{
      td:nth-child(1){
        border-right: 1px solid rgba(167, 167, 167, 0.36);;
      }
      td:nth-child(2){
        text-align: left;
        padding-left: 100px;
        border-right: 1px solid rgba(167, 167, 167, 0.36);;
      }
      td:nth-child(3){
        text-align: left;
        padding-left: 95px;
        padding-right: 5px;
      }
      td{
        vertical-align: middle;
        height: 40px;
        border: 1px solid #F2F2F2;
        text-align: center;
        color: #000000;
      }
      &:first-of-type{
        background-image: url('../../assets/PC/activity/table_header.png');
        background-size: 1127px 52px;
        td{
          background-color: transparent !important;
          font-weight: bold;
          color: #fff;
          height: 52px;
          font-size: 16px;
        }
      }
      &:nth-child(even){
        td{
          background-color: #fff;
        }
      }
      &:nth-child(odd){
        td{
          background-color: #F2F2F2;
        }
      }
    }
    td{
      padding: 10px;
    }
  }
}
.people-box{
  display: flex;
  flex-wrap: wrap;
  margin-top: 23px;
  .people:nth-of-type(3n){
    margin-right: 0;
  }
  .people{
    width: calc((100% - 50px) / 3);
    border: 1px solid #D0D0D0;
    border-radius: 16px;
    margin-right: 24px;
    margin-bottom: 20px;
    padding: 34px 0 45px;
    color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    .img_box{
      width: 134px;
      height: 134px;
      border: 1px solid #ccc;
      border-radius: 50%;
    }
    img{
      max-width: 134px;
      max-height: 134px;
      object-fit: fill;
    }
    .people-content{
      display: flex;
      flex-direction: column;
      align-items: center;
      .people-name{
        font-weight: bold;
        font-size: 18px;
        color: #6B18A8;
        margin-top: 30px;
      }
      .people-address{
        margin-top: 9px;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
      }
      .people-position{
        font-size: 16px;
        line-height: 22px;
        text-align: center;
      }
    }
    .people-bottom{
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 13px;
      background: #6A1BB2;
      border-radius: 0 0 20px 20px;
    }
  }
  
}
.activity-box{
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  .activity:nth-of-type(5n){
    margin-right: 0;
  }
  .activity{
    width: 216px;
    height: 89px;
    border: 1px solid #6B18A8;
    margin-right: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      max-width: 213px;
      max-height: 89px;
      object-fit: fill;
    }
  }
}
.ercode-box{
  margin-top: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  .ercode-img{
    width: 184px;
    height: 184px;
    margin-right: 23px;
    img{
      width: 100%
    }
  }
  .ercode-text{
    display: flex;
    flex-direction: column;
    .ercode-icon{
      display: flex;
      align-items: center;
      margin-top: 10px;
      img{
        background: #6B18A8;
        width: 20px;
        height: 20px;
        margin-right: 3px;
        border-radius: 5px;
      }
      span{
        font-size: 18px;
        font-weight: bold;
        color: #000;
      }
    }
    span{
      font-size: 18px;
      line-height: 29px;
    }
  }
}


@media (max-width: 750px) {
  .banner{
    img{
      width: vw(750);
    }
  }
  .module{
    width: 100%;
    padding: 0 vw(40);
    margin-bottom: vw(70);
  }
  .module-intro{font-size: vw(24); line-height: vw(36);padding: vw(55) 0 vw(33);
    p{font-size: vw(24);text-indent: vw(32); margin-bottom: vw(30);}
  }
  .module-title{font-size: vw(28); margin: 0 0 vw(20)}
  .module-box{
    .module-box-content:nth-child(3){
      margin-right: 0;
    }
    .module-box-content{
      width: vw(220); 
      height: vw(152);
      background-size: vw(220) vw(152);
      padding-left: vw(15);
      padding-top: vw(20);
      margin-right: vw(5);
      flex-direction: column;
      align-items: flex-start;
      .module-content-nav{
        flex-direction: column;
        .nav-time, .nav-address{
          display: flex;
          align-items: center;
          img{
            margin-right: vw(7);
          }
          span{
            margin-top: 0;
            font-size: vw(24);
          }
        }
        .nav-time{
          img{
            width: vw(18);
            height: vw(18);
          }
        }
        .nav-address{
          margin-top: vw(10);
          margin-left: 0;
          img{
            width: vw(16);
            height: vw(20);
          }
        }
      }
      
    }
    .select-active::before{
      border-right: 8px solid transparent;
      border-top:12px solid transparent;
      border-bottom: 12px solid #FFFFFF;
      border-left:8px solid transparent;
      bottom: vw(-51);
    }
    .select-active::after{
      border-right: 9px solid transparent;
      border-top:12px solid transparent;
      border-bottom:13px solid #6b18a8;
      border-left:9px solid transparent;
      bottom: vw(-51);
    }
  }

  .module-date-box{
    margin-top: vw(49);
    border-radius: vw(16);
    padding: 0 vw(28) vw(69);
    .date-title{
      font-size: vw(24);
      margin-top: vw(38);
      color: #6A1BB2;
    }
    .address-mobile{
      width: 100%;
      background: #EFE1F9;
      border-radius: vw(16);
      padding: vw(25) vw(10);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: vw(35);
      p{
        font-size: vw(24);
        line-height: vw(44);
      }
    }
    .data-more{
      text-align: center;
      font-size: vw(30);
      color: #000;
      margin: vw(40) 0;
    }
  }

  .item{
    margin-top: vw(16);
    .item-more{
      margin-top: vw(15);
      font-size: vw(22);
    }
    .item-header-left{
      width: 26%;
    }
    .item-header-center{
      width:49%;
    }
    .item-header-right{
      width: 25%;
    }
    table{
      width: 100%;
      border-collapse: collapse;
      border: 1px solid #aaa;
      tr{
        td:nth-child(1){
          padding: vw(13) 0;
        }
        td:nth-child(2){
          padding-left: vw(10);
        }
        td:nth-child(3){
          padding-left: vw(10);
          padding-right: vw(10);
        }
        td{
          font-size: vw(22);
          height: auto;
        }
        &:first-of-type{
          background-image: url('../../assets/PC/activity/table_header_m.png');
          background-size: vw(623) vw(56);
          td{
            height: auto;
            font-size: vw(24);
          }
        }
      }
      td{
        padding: vw(13);
      }
    }
  }

  .people-box{
    margin-top: vw(20);
    .people{
      width: 100%;
      border-radius: vw(20);
      margin-right: 0;
      margin-bottom: vw(16);
      flex-direction: row;
      padding: vw(20) 0 vw(40) vw(50);
      justify-content: flex-start;
      align-items: center;
      .img_box{
        width: vw(163);
        height: vw(163);
        border: 1px solid #ccc;
        border-radius: 50%;
      }
      img{
        max-width: vw(163);
        max-height: vw(163);
        object-fit: fill;
      }
      .people-content{
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-left: vw(30);
        p{width: 100%;}
        .people-name{
          margin-top: 0;
          font-size: vw(26);
          font-weight: bold;
        }
        .people-address{
          margin-top: vw(9);
          font-size: vw(24);
          text-align: left;
          line-height: vw(36);
        }
        .people-position{
          font-size: vw(24);
          margin-bottom: 0;
          text-align: left;
        }
      }

      .people-bottom{
        height: vw(13);
        border-radius: 0 0 vw(30) vw(30);
        bottom: vw(-1)
      }
    }
  }

  .activity-box{
    margin-top: vw(20);
    justify-content: center;
    .activity:nth-of-type(3n){
      margin-right: vw(5);
    }
    .activity{
      width: vw(200);
      height: vw(73);
      margin-right: vw(5);
      margin-bottom: vw(10);
      img{
        max-width: vw(155);
        max-height: vw(44);
        object-fit: fill;
      }
    }
    .activity:nth-child(1){
      img{
        max-width: vw(93);
        max-height: vw(44);
      }
    }
    .activity:nth-child(2){
      img{
        max-width: vw(155);
        max-height: vw(40);
      }
    }
    .activity:nth-child(3){
      img{
        max-width: vw(73);
        max-height: vw(67);
      }
    }
    .activity:nth-child(4){
      img{
        max-width: vw(118);
        max-height: vw(51);
      }
    }
    .activity:nth-child(5){
      img{
        max-width: vw(124);
        max-height: vw(36);
      }
    }
  }
  .ercode-box{
    margin-top: vw(45);
    flex-direction: column;
    align-items: center;
    .ercode-img{
      width: vw(216);
      height: vw(216);
      margin-right: 0;
    }
    .ercode-text{
      display: flex;
      flex-direction: column;
      .ercode-icon{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: vw(60);
        margin-bottom: vw(5);
        img{
          width: vw(23);
          height: vw(22);
          margin-right: vw(5);
        }
        span{
          font-size: vw(24);
          line-height: vw(24);
          font-weight: bold;
          margin-bottom: vw(-6)
        }
      }
      span{
        font-size: vw(24);
        line-height: vw(42);
        text-align: center;
        font-weight: bold;
      }
      &>span{
        margin-top: vw(30)
      }
    }
  }
}
</style>
